@import './src/scss/variables';
@import './src/scss/game';

:root {
  --size: 20px;
}

$size: 580px;
//$size: 80px;

.village {
  width: $size + 40px;
  height: $size + 40px;
  position: relative;
  margin: auto;
}

#garden {
  margin: 20px;
  background: white;
  position: absolute;
  height: $size;
  width: $size;
  left: 0;
  right: 0;
  //margin-left: auto;
  //margin-right: auto;

  &:after {
    border: 1px solid #b1b6bf;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    pointer-events: none;
  }

  &.with-wall {
    margin: 0;
    height: $size + 20px;
    width: $size + 20px;

    &:after {
      left: 20px;
      top: 20px;
      //bottom: 20px;
      //margin: 20px;
    }
  }

  &.show-grid {
    background-image: linear-gradient(#b1b6bf  1px, transparent 1px), linear-gradient(90deg, #b1b6bf 1px, transparent 1px);
    background-size: 20px 20px;
  }
}

.cell {
  position: absolute;
  height: var(--size);
  width: var(--size);
  background: green;
  z-index: 2;
}

.wall {
  background-color: white;
  //background-color: #e5e5e5;
  //background-color: grey;
  z-index: 9;
  //display: none;
  //visibility: hidden;
}

.food, .golden {
  background: red;
  z-index: 1;
}

.head {
  background: blue;
  z-index: 3;
}